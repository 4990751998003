<template>
    <v-card :loading="loading">
        <v-card-title class="text-h5 d-flex align-start">
            <div class="d-flex mt-0 ml-n2 mb-2">
                <v-btn
                    class="mr-2"
                    depressed
                    color="primary"
                    icon
                    rounded
                    @click="closeDialog"
                >
                    <v-icon size="25">mdi-close</v-icon>
                </v-btn>
                <span class="">Task</span>
                <v-btn icon small class="mx-2" @click="openEditDialog">
                    <v-icon>
                        mdi-pencil
                    </v-icon>
                </v-btn>
            </div>
            <v-spacer></v-spacer>
            <div class="d-flex">
                <div class="d-flex flex-center mr-2">
                    <v-icon size="28">
                        mdi-calendar
                    </v-icon>
                </div>
                <div class="d-flex">
                    <div class="pa-0 ma-0" v-if="task.startDate">
                        <span class="text-body-2 mb-n1" style="font-size: 10px"
                            >Started</span
                        >
                        <p class="pa-0 ma-0 mt-n1" style="font-size: 18px">
                            {{ formatDate(task.startDate) }}
                        </p>
                    </div>
                    <v-divider vertical class="mx-2" v-if="task.startDate" />
                    <div class="pa-0 ma-0">
                        <span
                            class="text-body-2 my-0 py-0 mb-n1"
                            style="font-size: 10px"
                            >Due date</span
                        >
                        <p class="pa-0 ma-0 mt-n1" style="font-size: 18px">
                            {{ formatDate(task.expectedDate) }}
                        </p>
                    </div>
                </div>
            </div>
        </v-card-title>

        <v-card-text>
            <v-row class="d-flex flex-wrap font-weight-regular mb-0 mx-1 pa-0">
                <v-col
                    class="d-flex pa-0 ma-0 text-body-1 font-weight-black"
                    cols="12"
                >
                    <v-icon class="mr-2">mdi-book-open-outline</v-icon>
                    <p class="mt-1 mx-0 mb-0 pa-0">
                        {{ task.description }}
                    </p>
                </v-col>
                <v-col cols="12" class="ma-0 pa-0">
                    <v-row
                        class="d-flex flex-wrap mt-2 mb-2 mx-0 pa-0"
                        v-if="task.projectId"
                    >
                        <p
                            class="ma-0 pa-0 underlineTag"
                            @click.stop="openProject(task.projectId)"
                            style="cursor: pointer"
                        >
                            Project {{ ` ${task.projectReference}` }}
                        </p>
                        <p
                            class="ma-0 ml-2 pa-0 underlineTag"
                            v-if="task.quoteId"
                            @click.stop="openQuote(task.quoteId)"
                            style="cursor: pointer"
                        >
                            Quote {{ ` ${task.quoteNumber}` }}
                        </p>
                        <p
                            class="ma-0 ml-2 pa-0 underlineTag"
                            v-if="task.cost"
                            @click.stop="openQuote(task.quoteId)"
                            style="cursor: pointer"
                        >
                            Cost {{ ` ${task.cost.split('/')[0]}` }}
                        </p>
                    </v-row>
                </v-col>
                <v-col cols="12" class="d-flex ma-0 pa-0">
                    <h3
                        @click="openHoursDialog"
                        style="cursor: pointer"
                        v-if="task.status == 'done'"
                    >
                        Hours: {{ task.hours || '-' }}
                    </h3>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-row class="px-4">
                        <v-text-field
                            v-model="task.notes"
                            label="Notes"
                            @focus="activateButtons"
                        />
                    </v-row>
                </v-col>
            </v-row>
            <v-row v-if="viewButtons">
                <v-spacer />
                <v-btn small icon class="mt-n4 mr-2" @click="saveNotes">
                    <v-icon>
                        mdi-check-circle-outline
                    </v-icon>
                </v-btn>
                <v-btn
                    small
                    icon
                    class="mt-n4 mr-2"
                    @click="viewButtons = false"
                >
                    <v-icon>
                        mdi-close-circle-outline
                    </v-icon>
                </v-btn>
            </v-row>
            <v-row no-gutters class="mt-5">
                <v-col cols="12">
                    <TaskTags
                        :task="task"
                        :createTask="false"
                        @replaceTask="replaceTask"
                    />
                </v-col>
            </v-row>
            <v-row class="d-flex mt-8 mb-1 mx-1">
                <div class="d-flex" v-if="createdBy">
                    <v-avatar size="28">
                        <v-img
                            v-if="createdBy.avatar"
                            :src="createdBy.avatar"
                            :alt="createdBy.name"
                        />
                        <v-icon x-large color="#707070" v-else
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                    <div class="ml-2 mt-n2">
                        <span
                            class="text-body-2 my-0 py-0"
                            style="font-size: 10px"
                            >Created By</span
                        >
                        <p
                            class="text-body-1 font-weight-black text-capitalize"
                            style="font-size: 18px"
                        >
                            {{ createdBy.name }}
                        </p>
                    </div>
                </div>
                <div class="mx-4">
                    <v-icon>
                        mdi-arrow-right
                    </v-icon>
                </div>
                <div class="d-flex" v-if="assignedTo">
                    <v-avatar size="28">
                        <v-img
                            v-if="assignedTo.avatar"
                            :src="assignedTo.avatar"
                            :alt="assignedTo.name"
                        />
                        <v-icon x-large color="#707070" v-else
                            >mdi-account-circle</v-icon
                        >
                    </v-avatar>
                    <div class="ml-2 mt-n2">
                        <span
                            class="text-body-2 my-0 py-0"
                            style="font-size: 10px"
                            >Assigned to</span
                        >
                        <p
                            class="text-body-1 font-weight-black text-capitalize"
                            style="font-size: 18px"
                        >
                            {{ assignedTo.name }}
                        </p>
                    </div>
                </div>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <TaskChild :task="task" />
                </v-col>
            </v-row>
        </v-card-text>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openHoursForm"
            persistent
            max-width="340px"
        >
            <HoursForm
                v-if="openHoursForm"
                :taskHours="taskHours"
                @closeHoursDialog="closeHoursDialog"
                @updateHours="updateHours"
            />
        </v-dialog>
        <!-- task  -->
        <v-dialog
            :retain-focus="false"
            v-model="openTaskForm"
            persistent
            max-width="640px"
        >
            <TaskForm
                v-if="openTaskForm"
                :task="selectedTask"
                :originalTask="originalTask"
                :createForm="createForm"
                @closeDialog="closeTaskFormDialog"
                @replaceTask="replaceTask"
                @closeTaskDetails="closeDialog"
                @deleteTask="deleteTask"
            />
        </v-dialog>
    </v-card>
</template>

<script>
import moment from 'moment'
import { loadImage } from '@/helpers/imageHandler.js'
import { mapMutations } from 'vuex'
import API from '@/services/api'
import TaskChild from '@/components/Tasks/TaskChild.vue'
import HoursForm from '@/components/Tasks/HoursForm.vue'
import TaskForm from '@/components/Tasks/TaskForm.vue'
import TaskTags from '@/components/Tasks/TaskTags.vue'
import _ from 'lodash'

export default {
    name: 'TaskDetails',
    props: {
        task: Object,
    },
    components: {
        TaskChild,
        HoursForm,
        TaskForm,
        TaskTags,
    },
    data: () => ({
        loading: false,
        notes: '',
        headers: [
            {
                text: 'name',
                value: 'description',
                align: 'left',
            },
            {
                text: 'percentage',
                value: 'percentage',
                align: 'center',
            },
            {
                text: 'actions',
                value: 'actions',
                align: 'center',
            },
        ],
        createdBy: undefined,
        assignedTo: undefined,
        companyId: JSON.parse(localStorage.getItem('company')),
        folderUsersPictures: 'users_pictures',
        taskHours: undefined,
        openHoursForm: false,
        rules: {
            required: v => !!v || 'Required',
            value: v => !v || v.size > 0 || 'Required',
        },
        openTaskForm: false,
        selectedTask: {},
        originalTask: {},
        createForm: false,
        viewButtons: false,
    }),
    async mounted() {
        try {
            this.loading = true
            const {
                data: { user: createdBy },
            } = await API.getOne(this.task.createdBy)
            this.createdBy = createdBy
            const {
                data: { user: assignedTo },
            } = await API.getOne(this.task.assignedTo)
            this.assignedTo = assignedTo
            if (this.createdBy.picture) {
                const path = `${this.companyId}/${this.folderUsersPictures}`
                this.createdBy.avatar = await loadImage(
                    path,
                    this.createdBy.picture
                )
            }
            if (this.assignedTo.picture) {
                const path = `${this.companyId}/${this.folderUsersPictures}`
                this.assignedTo.avatar = await loadImage(
                    path,
                    this.assignedTo.picture
                )
            }
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations([
            'setErrorItems',
            'setClientQuote',
            'setProjectId',
            'setRoute',
        ]),
        async saveNotes() {
            try {
                this.loading = true
                const task = await API.updateTask(this.task.id, {
                    assignedTo: this.task.assignedTo,
                    notes: this.task.notes,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
                task.startDate = moment.unix(
                    this.task.startDate._seconds || this.task.startDate.seconds
                )
                task.expectedDate = moment.unix(
                    this.task.expectedDate._seconds ||
                        this.task.expectedDate.seconds
                )
                this.replaceTask(task)
                this.viewButtons = false
            } catch (error) {
                this.setErrorItems(error.message)
            } finally {
                this.loading = false
            }
        },
        activateButtons() {
            this.viewButtons = true
        },
        openEditDialog() {
            this.selectedTask = _.cloneDeep(this.task)
            this.originalTask = _.cloneDeep(this.task)
            this.createForm = false
            this.openTaskForm = true
        },
        closeTaskFormDialog: function() {
            this.createForm = false
            this.openTaskForm = false
            this.selectedTask = {}
            this.originalTask = {}
        },
        replaceTask(task) {
            Object.keys(task).forEach(key => {
                this.task[key] = task[key]
            })
            this.$emit('replaceTask', task)
        },
        async openProject(projectId) {
            try {
                this.loading = true
                this.setRoute(undefined)
                const project = await API.getProjects({ projectId })
                this.setProjectId(projectId)
                if (project.archive) {
                    this.$router.push({
                        path: '/archive-projects',
                    })
                } else {
                    this.$router.push({
                        path: 'projects',
                    })
                }
                this.setRoute('projects')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async openQuote(quoteId) {
            try {
                this.loading = true
                this.setRoute(undefined)
                const {
                    data: { quotes },
                } = await API.getQuotes({ quoteId })
                const quote = quotes[0]
                this.setClientQuote(quoteId)
                if (quote.status === 'SENT' && !quote.archive) {
                    this.$router.push({
                        name: 'main board',
                    })
                } else if (
                    quote.archive ||
                    quote.status === 'AWARDED' ||
                    quote.status === 'LOST'
                ) {
                    this.$router.push({
                        name: 'history',
                    })
                } else {
                    this.$router.push({
                        name: 'main board',
                    })
                }
                this.setRoute('sales')
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(date) {
            return moment
                .unix(date.seconds ? date.seconds : date._seconds)
                .format('MMM DD')
        },
        closeDialog() {
            this.$emit('closeDialog')
        },
        deleteTask(taskId) {
            this.$emit('deleteTask', taskId)
        },
        async updateHours(hours) {
            try {
                await API.updateTask(this.task.id, {
                    hours,
                    assignedTo: this.task.assignedTo,
                    notificationId: 'rCJFinVt7UiC6uTysz2i',
                })
                this.task.hours = Number(hours)
                this.closeHoursDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            }
        },
        closeHoursDialog() {
            this.taskHours = undefined
            this.openHoursForm = false
        },
        openHoursDialog() {
            this.taskHours = this.task.hours
            this.openHoursForm = true
        },
    },
}
</script>

<style scoped>
.borderRounded {
    border-radius: 15px;
}

.underlineTag {
    text-decoration: underline #3277d1;
    font-weight: bold;
    font-size: 13px;
    color: #3277d1;
}
</style>
