<template>
    <v-card :loading="loading">
        <v-card-title>
            <span class="text-h5">Select Quote</span>
        </v-card-title>

        <v-card-text>
            <v-container>
                <v-row>
                    <v-autocomplete
                        flat
                        solo
                        hide-details
                        outlined
                        v-model="importedQuote"
                        :items="quotes"
                        item-text="name"
                        item-value="id"
                        label="Quote"
                    >
                    </v-autocomplete>
                </v-row>
                <v-row class="mt-4">
                    <v-col cols="12" class="px-0">
                        <v-data-table
                            :loading="loading"
                            :headers="headers"
                            class="elevation-0"
                            :items="costs"
                            :mobile-breakpoint="0"
                            hide-default-footer
                            disable-pagination
                            :height="$vuetify.breakpoint.lg ? '30vh' : '30vh'"
                            fixed-header
                        >
                            <!--HEADERS-->
                            <template v-slot:[`header.selectAll`]="{ header }">
                                <div class="d-flex">
                                    <h3 class="my-0 mx-2">{{ header.text }}</h3>
                                    <v-simple-checkbox
                                        color="primary"
                                        class="mt-n1"
                                        v-model="checkAll"
                                        @click="selectAllCosts"
                                    />
                                </div>
                            </template>
                            <!--ITEMS-->
                            <template v-slot:[`item.salePrice2`]="{ item }">
                                <p class="my-0">{{ formatNumber(item) }}</p>
                            </template>
                            <template v-slot:[`item.selectAll`]="{ item }">
                                <v-simple-checkbox
                                    color="primary"
                                    class="mt-n1"
                                    v-model="item.check"
                                />
                            </template>
                        </v-data-table>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-btn color="red" text @click="close">
                Cancel
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                rounded
                depressed
                dark
                :loading="loading"
                @click="importCosts"
            >
                Import
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import { mapMutations } from 'vuex'
import API from '@/services/api'

export default {
    name: 'ImportCosts',
    props: {
        quote: {
            type: Object,
            required: true,
        },
    },
    data: () => ({
        checkAll: false,
        loading: true,
        quotes: [],
        importedQuote: {},
        costs: [],
        headers: [
            {
                text: 'DESCRIPTION',
                value: 'reference',
                class: 'grey lighten-3',
                sortable: false,
                align: 'left',
            },
            {
                text: 'CATEGORY',
                value: 'category',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'QTY',
                value: 'quantity',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'SALE PRICE',
                value: 'salePrice2',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
            },
            {
                text: 'Select all',
                value: 'selectAll',
                class: 'grey lighten-3',
                sortable: false,
                align: 'center',
                width: '150',
            },
        ],
        selectQuote: {},
    }),
    watch: {
        async importedQuote() {
            try {
                this.loading = true
                if (this.importedQuote) {
                    this.checkAll = false
                    this.selectAllCosts()
                    this.selectQuote = this.quotes.find(
                        quote => quote.id == this.importedQuote
                    )
                    let costs = await API.getCosts(this.importedQuote)
                    costs = costs.filter(cost => cost.type)
                    this.costs = costs.filter(cost => cost.type == 'cost')
                    this.costs.sort((a, b) => a.index - b.index)
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
    async mounted() {
        try {
            this.loading = true
            this.quotes = await API.getBasicInfoQuotes()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        selectAllCosts() {
            this.costs.forEach((cost, index) => {
                this.$set(this.costs, index, { ...cost, check: this.checkAll })
            })
        },
        formatNumber(item) {
            return new Intl.NumberFormat('de-DE', {
                style: 'currency',
                currency: this.selectQuote.currency,
                maximumFractionDigits: 2,
            }).format(item.salePrice2)
        },
        async importCosts() {
            try {
                this.loading = true
                const costs = await API.importCosts({
                    originQuote: this.importedQuote,
                    destinationQuote: this.quote.id,
                    costs: this.costs
                        .filter(cost => cost.check)
                        .map(cost => cost.id),
                })
                await this.$emit('importCosts', costs)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        close() {
            this.$emit('close')
        },
    },
}
</script>

<style scoped>
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
tr:hover {
    background: unset !important;
}
th,
td {
    font-size: 0.875rem !important;
}
</style>
