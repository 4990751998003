<template>
    <div>
        <v-data-table
            v-if="costVersions"
            :loading="loading"
            :headers="headers"
            v-model="selected"
            :items="historyCosts"
            class="elevation-0 mt-2"
            :mobile-breakpoint="0"
            :disable-pagination="true"
            hide-default-footer
        >
            <template v-slot:[`item.createdOn`]="{ item }">
                <p class="my-0">
                    {{ item.createdOn }}
                </p>
            </template>
            <template v-slot:[`item.file`]="{ item }">
                <a @click="openFile(item.file)">{{ item.file }}</a>
            </template>
            <template v-slot:[`item.bidDocuments`]="{ item }">
                <div v-if="item.files">
                    <a
                        v-for="(file, index) of loadDocuments(
                            'bidDocuments',
                            item.files
                        )"
                        :key="index"
                        @click="openFile(file.file)"
                        >{{ file && file.name }}<br
                    /></a>
                </div>
            </template>
            <template v-slot:[`item.attached`]="{ item }">
                <div v-if="item.files">
                    <a
                        v-for="(file, index) of loadDocuments(
                            'attached',
                            item.files
                        )"
                        :key="index"
                        @click="openFile(file.file)"
                        >{{ file && file.name }}<br
                    /></a>
                </div>
            </template>
            <template v-slot:[`item.sentTo`]="{ item }">
                <div v-if="item.sentTo">
                    <p
                        v-for="email of item.sentTo"
                        :key="email"
                        class="my-0 py-0"
                    >
                        {{ email }}
                    </p>
                </div>
            </template>
            <template v-slot:[`item.globalCurrency`]="{ item }">
                <p class="my-1">
                    {{
                        item.costExchanges && item.costExchanges.globalCurrency
                    }}
                </p>
            </template>
            <template v-slot:[`item.exchange`]="{ item }">
                <p class="my-1">
                    {{ item.exchange }} {{ item.exchange && item.currency }}
                </p>
            </template>
            <template v-slot:[`item.totalCosts`]="{ item }">
                <p class="my-1">
                    {{
                        new Intl.NumberFormat('de-DE', {
                            style: 'currency',
                            currency: item.currency,
                            maximumFractionDigits: 0,
                        }).format(item.allCost ? item.allCost : 0)
                    }}
                </p>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
                <div class="d-flex justify-center">
                    <v-btn
                        v-if="quoteDetails"
                        icon
                        elevation="0"
                        small
                        @click="seeCosts(item)"
                    >
                        <v-icon>mdi-eye</v-icon>
                    </v-btn>
                    <v-btn
                        v-if="sendQuote"
                        class="ml-2"
                        icon
                        elevation="0"
                        small
                        @click="openSendForm(item)"
                    >
                        <v-icon>mdi-send</v-icon>
                    </v-btn>
                </div>
            </template>
        </v-data-table>
        <v-dialog
            :retain-focus="false"
            :fullscreen="$vuetify.breakpoint.mobile"
            v-model="dialogCost"
            max-width="750px"
        >
            <v-card>
                <v-banner sticky color="white" class="pt-1 pb-1" single-line>
                    <v-card-title class="text-h5 pt-0 pb-0 pl-0">
                        <v-btn
                            class="mr-3"
                            depressed
                            color="primary"
                            icon
                            rounded
                            @click="closeDialog"
                        >
                            <v-icon>mdi-close</v-icon>
                        </v-btn>

                        Costs
                    </v-card-title>
                </v-banner>
                <v-card-subtitle v-if="costInfo.costExchanges" class="mt-4">
                    <strong>Exchanges</strong>
                    <br />
                    <span
                        v-for="(exchange, index) of costInfo.costExchanges
                            ? costInfo.costExchanges.exchanges
                            : []"
                        :key="index"
                        class="mr-3"
                    >
                        {{ `${exchange.currency} ${exchange.money}` }}
                    </span>
                </v-card-subtitle>
                <v-card-text>
                    <v-data-table
                        :headers="headersCosts"
                        class="elevation-0 mt-1"
                        :items="orderedCosts"
                        hide-default-footer
                        :mobile-breakpoint="0"
                        :disable-pagination="true"
                    >
                        <template v-slot:[`item.type`]="{ item }">
                            <p class="my-0 text-capitalize">
                                {{ item.type }}
                            </p>
                        </template>
                        <template v-slot:[`item.salePrice`]="{ item }">
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                }).format(item.salePrice2)
                            }}
                        </template>
                        <template v-slot:[`item.pricePerQuantity`]="{ item }">
                            {{
                                new Intl.NumberFormat('de-DE', {
                                    style: 'currency',
                                    currency: quote.currency,
                                }).format(
                                    item.salePrice /
                                        costInfo.exchange /
                                        item.quantity
                                )
                            }}
                            / {{ item.measure }}
                        </template>
                        <template v-slot:[`item.actions`]="{ item }">
                            <v-btn
                                rounded
                                color="primary"
                                elevation="0"
                                small
                                @click="editItem(item)"
                            >
                                VIEW
                            </v-btn>
                        </template>
                        <template v-slot:footer>
                            <v-simple-table dense class="my-4">
                                <tfoot class="grey lighten-3">
                                    <tr>
                                        <td class="font-weight-bold">
                                            TOTAL COSTS
                                        </td>
                                        <td class="font-weight-bold text-right">
                                            {{
                                                new Intl.NumberFormat('de-DE', {
                                                    style: 'currency',
                                                    currency: quote.currency,
                                                }).format(
                                                    costInfo.allCost
                                                        ? costInfo.allCost
                                                        : 0
                                                )
                                            }}
                                        </td>
                                    </tr>
                                </tfoot>
                            </v-simple-table>
                        </template>
                    </v-data-table>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-dialog
            :retain-focus="false"
            :fullscreen="$vuetify.breakpoint.mobile"
            v-model="editDialog"
            persistent
            max-width="1200px"
        >
            <NewQuoteCostForm
                v-if="editDialog && quote"
                title="Item Details"
                :costs="costs"
                :originalCost="originalCost"
                :cost="costToEdit"
                :quote="quote"
                :settings="settings"
                @closeDialog="closeEditItem"
                :loading="loading"
                :version="true"
                :currency="costInfo.currency"
                :exchange="costInfo.exchange"
                :costExchanges="costInfo.costExchanges"
            />
        </v-dialog>
        <!--Resend quote-->
        <SendQuote
            :quote="quote"
            :client="quote ? quote.client : ''"
            :resend="resend"
            :costs="costs"
            :docsBySend="docsBySend"
            @closeDialog="closePDF"
            @openFilePDF="openFile"
            v-model="dialogSend"
        />
    </div>
</template>

<script>
import { storage } from '@/services/firebase'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import NewQuoteCostForm from '@/components/Quotes/NewQuoteCostForm'
import SendQuote from '@/components/Quotes/SendQuote'
import _ from 'lodash'

export default {
    name: 'QuoteChangelog',
    components: {
        NewQuoteCostForm,
        SendQuote,
    },
    props: {
        id: String,
        quote: Object,
        costVersions: Object,
        settings: Array,
        costs: Array,
    },
    data() {
        return {
            loading: false,
            quoteDetails: false,
            sendQuote: false,
            companyId: JSON.parse(localStorage.getItem('company')),
            folderDocuments: 'quotes_documents',
            folderClientsPictures: 'clients_pictures',
            folderUsersPictures: 'users_pictures',
            headersCosts: [
                {
                    text: 'Type',
                    value: 'type',
                    class: 'grey lighten-3',
                },
                {
                    text: 'DESCRIPTION',
                    value: 'reference',
                    class: 'grey lighten-3',
                },
                {
                    text: 'QUANTITY',
                    value: 'quantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'PRICE PER QUANTITY',
                    value: 'pricePerQuantity',
                    class: 'grey lighten-3',
                },
                {
                    text: 'SALE PRICE',
                    value: 'salePrice',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    class: 'grey lighten-3',
                    align: 'left',
                },
            ],
            headers: [
                {
                    text: 'DATE',
                    value: 'createdOn',
                    class: 'grey lighten-3',
                    width: 200,
                    sortable: true,
                },
                {
                    text: 'QUOTES',
                    value: 'file',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 300,
                },
                {
                    text: 'BID DOCUMENTS',
                    value: 'bidDocuments',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 300,
                },
                {
                    text: 'ATTACHED',
                    value: 'attached',
                    class: 'grey lighten-3',
                    sortable: false,
                    width: 300,
                },
                {
                    text: 'SENT TO',
                    value: 'sentTo',
                    class: 'grey lighten-3',
                    align: 'left',
                    sortable: false,
                    width: 300,
                },
                {
                    text: 'BUSINESS CURRENCY',
                    value: 'globalCurrency',
                    class: 'grey lighten-3',
                    align: 'left',
                    sortable: false,
                },
                {
                    text: 'RATE',
                    value: 'exchange',
                    class: 'grey lighten-3',
                    align: 'left',
                },
                {
                    text: 'TOTAL',
                    value: 'totalCosts',
                    class: 'grey lighten-3',
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    class: 'grey lighten-3',
                    align: 'center',
                    sortable: false,
                },
            ],
            costInfo: {},
            dialogCost: false,
            selected: [],
            totalCosts: 0,
            error: null,
            errorMsg: null,
            editDialog: false,
            costToEdit: null,
            exchange: null,
            originalCost: null,
            dialogSend: false,
            docsBySend: [],
            resend: false,
            historyCosts: [],
        }
    },
    watch: {
        quote: async function() {
            this.updateSeeMenuAction(false)
            if (!this.quote.client) {
                const {
                    data: { clients },
                } = await API.getClient({ clientId: this.quote.clientId })
                this.quote.client = clients
            }
            if (this.contacts.length === 0) {
                await this.getContacts()
            }
            if (this.users.length === 0) {
                await this.getUsers()
            }
            if (!this.quote.mainContact) {
                this.quote.mainContact = this.users.find(
                    user => this.quote.userId == user.id
                )
            }
            this.updateSeeMenuAction(true)
        },
    },
    computed: {
        ...mapState(['contacts', 'users']),
        orderedCosts() {
            let versionCosts = this.costInfo.costs

            if (versionCosts) {
                let costItems = versionCosts.filter(c => c.type == 'cost')
                let alternateItems = versionCosts.filter(
                    c => c.type == 'alternate'
                )
                costItems.sort((a, b) => a.index - b.index)
                alternateItems.sort((a, b) => {
                    let dateA = a.createdOn._seconds || a.createdOn.seconds
                    let dateB = b.createdOn._seconds || b.createdOn.seconds
                    return dateA - dateB
                })
                versionCosts = costItems.concat(alternateItems)
            }

            return versionCosts
        },
    },
    mounted() {
        if (
            this.costVersions.historyCosts &&
            this.costVersions.historyCosts.length > 0
        ) {
            this.historyCosts = this.costVersions.historyCosts.slice()
            this.historyCosts.forEach(element => {
                element.allCost = 0
                element.costs.forEach(cost => {
                    if (
                        cost.type
                            ? cost.type == 'cost'
                            : cost.alternate == undefined
                    ) {
                        cost.type = 'cost'
                        element.allCost += cost.salePrice2
                    } else cost.type = 'alternate'
                })
                element.date = moment(
                    element.createdOn,
                    'MMM/DD/YYYY h:mm a'
                ).unix()
            })
            this.historyCosts.sort((a, b) => b.date - a.date)
        } else {
            this.historyCosts = []
        }
    },
    methods: {
        ...mapActions([
            'addUsersAction',
            'addContactsAction',
            'updateSeeMenuAction',
        ]),
        openSendForm(item) {
            if (item.files) {
                for (let file of item.files) {
                    if (file.attach) {
                        this.docsBySend.push(file)
                    }
                }
            }
            this.docsBySend.push(item.file)
            this.dialogSend = true
            this.resend = true
        },
        loadDocuments(_type, _files) {
            let arr = []
            for (let file of _files) {
                if (file.attach !== undefined) {
                    if (_type == 'attached' && file.attach) {
                        arr.push(file)
                    } else if (_type == 'bidDocuments' && !file.attach) {
                        arr.push(file)
                    }
                }
            }
            return arr
        },
        closeEditItem() {
            this.editDialog = false
        },
        editItem(item) {
            this.costToEdit = _.cloneDeep(item)
            this.originalCost = _.cloneDeep(item)
            this.editDialog = true
        },
        seeCosts(item) {
            this.costInfo = _.cloneDeep(item)
            this.dialogCost = true
        },
        closeDialog() {
            this.dialogCost = false
        },
        closePDF() {
            this.docsBySend = []
            this.dialogSend = false
            this.resend = false
        },
        openFile: async function(file) {
            const nameFile = this.quote.files.find(x => x.file == file)
            const id = this.quote.id
            const path = `${this.companyId}/${this.folderDocuments}/${id}`
            const storageRef = storage().ref(path)
            const documentRef = storageRef.child(
                nameFile ? nameFile.file : file
            )
            const url = await documentRef.getDownloadURL()
            if (this.$device.ios) {
                window.location.assign(url)
            } else {
                window.open(url, '_blank')
            }
        },
        async getContacts() {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                const {
                    data: { contacts },
                } = await API.getContacts(this.companyId)
                this.addContactsAction(contacts)
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },

        getUsers: async function() {
            try {
                this.error = false
                this.errorMsg = null
                this.loading = true
                const {
                    data: { users },
                } = await API.getUsers(this.companyId)
                for (const user of users) {
                    user.createdOn = moment
                        .unix(user.createdOn._seconds, 'MMM/DD/YYYY')
                        .format('MMM/DD/YYYY HH:mm')
                }
                this.addUsersAction(users)
            } catch (error) {
                this.error = true
                this.errorMsg = error.message
            } finally {
                this.loading = false
            }
        },
    },
    async created() {
        const {
            data: { user },
        } = await API.getMyInfo()
        this.quoteDetails =
            user.permissions.find(x => x == 'quoteDetails') !== undefined
        this.sendQuote =
            user.permissions.find(x => x == 'sendQuote') !== undefined
    },
}
</script>

<style scoped>
.text-singleline {
    line-height: 0.75rem;
}
.v-data-table >>> table {
    border: thin solid #eeeeee;
}
.no-border {
    border: none !important;
}
tr:hover {
    background: unset !important;
}
tr:not(:last-child) > td.border-grey {
    border-bottom: thin solid #eeeeee !important;
}
</style>
